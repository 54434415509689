<template>
  <div class="integral_details_page">
    <div class="teacher_block" v-show="$route.query.type === 'teacher'">
      <!-- <div class="teacher_block_top">班级积分获取指南</div>
      <div class="teacher_block_title">上课获得积分的权重：37%左右</div>
      <div class="teacher_block_title">评分获得的积分权重：17%左右</div>
      <div class="teacher_block_title">测试获得的积分权重：17%左右</div>
      <div class="teacher_block_title">配课获得积分权重：14%左右</div>
      <div class="teacher_block_title">学生测试报告：9%左右</div> -->
      <div class="teacher_block_title">
        <!-- <div>班级测试报告：6%左右</div> -->
        <div>
          当前积分：<span>{{ $route.query.total }}</span>
        </div>
      </div>
    </div>
    <myTable
      :handle="false"
      :list="list"
      :columns="columns"
      :title="
        $route.query.type === 'school'
          ? '幼儿园积分日志'
          : $route.query.type === 'stu'
          ? '学生积分日志'
          : ''
      "
    >
    </myTable>
    <comPagination :params="params" @changePage="changePage"></comPagination>
  </div>
</template>

<script>
import myTable from "@/components/admin/table.vue";
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "headerSchool",
  components: {
    comPagination,
    myTable,
  },
  data() {
    return {
      //班级列表
      columns: [
        {
          prop: "id",
          label: "序号",
        },
        {
          prop: "createtime",
          label: "日期",
        },
        {
          prop: "memo",
          label: "积分描述",
        },
        {
          prop: "score",
          label: "积分状态",
        },
      ],
      list: [],
      //分页
      params: {
        page: 0,
        size: 10,
        total: 0,
      },
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    //初始化
    init() {
      if (this.$route.query.types === "school") {
        this.getSchool();
      }
      if (this.$route.query.types === "stu") {
        this.getTeacherInfo();
      }
      if (this.$route.query.types === "teacher") {
        //教师详情
        this.getTeacherDetail();
      }
    },
    getTeacherDetail() {
      //获取教师详情
      let data = {
        userId: this.$route.query.userId,
      };
      this.api.admin.findTeacherRankingDetail(this.params, data).then((res) => {
        // console.log(res);
        let arr = res.data.list;
        arr.forEach((item) => {
          let date = item.createtime * 1000;
          item.createtime = this.common.dateFormat(date);
          item.score = `+${item.score}`;
        });
        this.list = arr;

        this.params.page = res.data.pageNum;
        this.params.total = res.data.total;
      });
    },
    getTeacherInfo() {
      var node = JSON.stringify(localStorage.getItem("userInfo.userId"));
      console.log(node);
      // let type = this.$route.query.type
      let data = {
        // userId:  type === 'school' ? this.$store.state.userInfo.schoolId : this.$route.query.id,
        // roleId: type === 'teacher' ? 4 : type === 'stu' ? 3 : 2
        // schoolId:this.$store.state.userInfo.schoolId,
        // roleId: 4,
        userId: this.$route.query.id,
      };
      this.api.admin.detailIntegra(this.params, data).then((res) => {
        // console.log(res);
        let arr = res.data.list;
        arr.forEach((item) => {
          let date = item.createtime * 1000;
          item.createtime = this.common.dateFormat(date);
          item.score = `+${item.score}`;
        });
        this.list = arr;

        this.params.page = res.data.pageNum;
        this.params.total = res.data.total;
      });
    },
    // 获取学校
    getSchool() {
      var node = JSON.stringify(localStorage.getItem("userInfo.userId"));
      console.log(node);
      // let type = this.$route.query.type
      let data = {
        // userId:  type === 'school' ? this.$store.state.userInfo.schoolId : this.$route.query.id,
        // roleId: type === 'teacher' ? 4 : type === 'stu' ? 3 : 2
        roleId: 4,
        schoolId: this.$store.state.userInfo.schoolId,
        // userId: this.$route.query.id,
      };
      this.api.admin.schoollIntegra(this.params, data).then((res) => {
        let arr = res.data.list;
        arr.forEach((item) => {
          let date = item.createtime * 1000;
          item.createtime = this.common.dateFormat(date);
          item.score = `+${item.score}`;
        });
        this.list = arr;

        this.params.page = res.data.pageNum;
        this.params.total = res.data.total;
      });
    },

    //分页 todo 待测试
    changePage(val) {
      this.params.page = val;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.integral_details_page {
  .teacher_block {
    &_top {
      width: 100%;
      height: 50px;
      font-size: 30px;
      background: pink;
      color: #cc1a30;
      @include flex();
    }

    &_title {
      @include flex(row, space-between, center);
      font-size: 24px;
      color: #cc1a30;
    }
  }
}
</style>
<style lang="scss">